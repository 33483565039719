import './App.css';
import {useEffect, useState} from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AdminPage from "./AdminPage";
import axios from "axios"

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/admin" element={<AdminPage />} />
            </Routes>
        </Router>
    );
}

function Home() {
    const calculateTimeRemaining = () => {
        const today = new Date();
        const targetDate = new Date('2024-09-07T16:00:00');
        const timeDifferenceInMilliseconds = targetDate - today;

        // Calculate days, hours, minutes, and seconds
        const daysRemaining = Math.floor(timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24));
        const hoursRemaining = Math.floor((timeDifferenceInMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutesRemaining = Math.floor((timeDifferenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
        const secondsRemaining = Math.floor((timeDifferenceInMilliseconds % (1000 * 60)) / 1000);

        return {
            daysRemaining,
            hoursRemaining,
            minutesRemaining,
            secondsRemaining
        };
    };

    const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());
    const [presenceSelection, setPresenceSelection] = useState(false);
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [host, setHost] = useState(0);
    const [kidMenu, setKidMenu] = useState(false);
    const [note, setNote] = useState("");
    const [hostsName, setHostsName] = useState([]);
    const [hostsSurname, setHostsSurname] = useState([]);
    const [hostsMenu, setHostsMenu] = useState([]);
    const [copied, setCopied] = useState(false)

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimeRemaining(calculateTimeRemaining());
        }, 36000);

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        document.getElementById("presenceConfirm").value = "";
    }, []);

    useEffect(() => {
        let timer;
        if (copied) {
            timer = setTimeout(() => {
                setCopied(false);
            }, 3000);
        }

        return () => clearTimeout(timer);
    }, [copied]);

    const Divider = () => {
        return (
            <div className="row justify-content-center my-5">
                <div className="col-12 col-md-10 col-lg-8 text-center">
                    <img src="/img/divider-2.png" alt="Divisore" className="img-fluid"/>
                </div>
            </div>
        )
    }

    const handleOnChangePresence = (event) => {
        if (event.target.value === 'yes') {
            setPresenceSelection(true)
        } else {
            setPresenceSelection(false)
            setKidMenu(false)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        let data = {};
        let hostsData = [];

        hostsName.forEach((name, index) => {
            if (index < host) {
                let actualHost = {
                    name: name,
                    surname: hostsSurname[index],
                    menu: hostsMenu[index]
                }

                hostsData.push(actualHost);
            }
        });

        data.name = name;
        data.surname = surname;
        data.confirmed = presenceSelection;
        data.host = host;
        data.kidMenu = kidMenu;
        data.note = note;
        data.hostsData = hostsData;
        data.TMS = new Date().toISOString();

        axios.post(`https://nicolavitrani-a4783-default-rtdb.europe-west1.firebasedatabase.app/responses.json`, data).then(response => {
            if (response.status === 200) {
                window.alert("Grazie per la risposta, a presto!")    ;
                window.location.reload()
            } else {
                window.alert("Errore nella richiesta, riprova")
            }
        });
    }

    const handleInputHostsName = (index, value) => {
        setHostsName((prevInputValues) => {
            const updatedInputValues = [...prevInputValues];
            updatedInputValues[index] = value;
            return updatedInputValues;
        });
    }

    const handleInputHostsSurname = (index, value) => {
        setHostsSurname((prevInputValues) => {
            const updatedInputValues = [...prevInputValues];
            updatedInputValues[index] = value;
            return updatedInputValues;
        });
    }

    const handleInputHostsMenu = (index, value) => {
        setHostsMenu((prevInputValues) => {
            const updatedInputValues = [...prevInputValues];
            updatedInputValues[index] = value;
            return updatedInputValues;
        });
    }

    const inputHosts = [];
    for (let i = 0; i < host; i++) {

        inputHosts.push(
            <div className="row justify-content-center my-4" key={i}>
                <div className="col-12 mb-3 text-center fw-bold">
                    Ospite {i+1}
                </div>
                <div className="col-12 col-md-4 mb-3">
                    <label className="form-label">Nome</label>
                    <input className="form-control" type="text" value={hostsName[i]} onChange={(e) => {
                        handleInputHostsName(i, e.target.value)
                    }} required/>
                </div>
                <div className="col-12 col-md-4 mb-3">
                    <label className="form-label">Cognome</label>
                    <input className="form-control" type="text" value={hostsSurname[i]} onChange={(e) => {
                        handleInputHostsSurname(i, e.target.value)
                    }} required/>
                </div>
                <div className="col-12 col-md-4 mb-3">
                    <label className="form-label">Tipo di menù</label>
                    <select className="form-select" value={hostsMenu[i]} onChange={(e) => {
                        handleInputHostsMenu(i, e.target.value)
                    }} required>
                        <option value="">Seleziona una risposta</option>
                        <option value="adult">Menù adulto</option>
                        <option value="baby">Menù bambino</option>
                    </select>
                </div>
            </div>
        )
    }

    const handleOnClickToCopyIban = (iban) => {
        let textarea = document.createElement("textarea");
        textarea.value = iban;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);

        setCopied(true)
    }

    return (
        <div className="container-fluid">
            <div className="row min-vh-100 justify-content-center align-items-center sfondo-top">
                <div className="col-12">
                    <div className="row justify-content-center pb-5">
                        <div className="col-12 text-center mb-4">
                            <h1 className="d-none d-md-block text-center display-3 font-elegance">
                                Enrica e Nicola
                            </h1>
                            <h1 className="d-md-none text-center fs-3rem font-elegance">
                                <div className="text-center">
                                    Enrica
                                </div>
                                <div className="text-center">
                                    e
                                </div>
                                <div className="text-center">
                                    Nicola
                                </div>
                            </h1>
                        </div>
                        <div className="col-12 text-center">
                            <h2 className="text-center">
                                7 Settembre 2024<br/> Lucera
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
            <Divider></Divider>
            <div className="container-fluid">
                <div className="row justify-content-center my-3">
                    <div className="col-12 col-md-9 col-lg-6 text-center mt-5">
                        <h3 className="row justify-content-center fs-5">
                            <div className="col-3 text-center">
                                <div className="fs-1 mb-2">
                                    {timeRemaining.daysRemaining}
                                </div>
                                <div className="text-center">
                                    giorni
                                </div>
                            </div>
                            <div className="col-3 text-center">
                                <div className="fs-1 mb-2">
                                    {timeRemaining.hoursRemaining}
                                </div>
                                <div className="text-center">
                                    ore
                                </div>
                            </div>
                            <div className="col-3 text-center">
                                <div className="fs-1 mb-2">
                                    {timeRemaining.minutesRemaining}
                                </div>
                                <div className="text-center">
                                    minuti
                                </div>
                            </div>
                        </h3>
                    </div>
                </div>
                <div className="row justify-content-center align-items-center min-vh-100 sfondo-center">
                    <div className="col-12">
                        <div className="row justify-content-center my-3">
                                <div className="col-12 col-md-10 col-lg-8 text-center my-3">
                                    <p className="text-center font-elegance fs-2">
                                        Insieme alle nostre famiglie Vi invitiamo a celebrare con noi il nostro matrimonio
                                    </p>
                                </div>
                        </div>
                        <div className="row justify-content-center my-3">
                            <div className="col-12 col-md-10 col-lg-8 text-center my-3">
                                <p className="text-center text-uppercase fs-2 fw-bold">
                                    Sabato 7 Settembre 2024
                                </p>
                            </div>
                        </div>
                        <div className="row justify-content-center my-3">
                            <div className="col-12 col-md-10 col-lg-8 text-center my-3">
                                <p className="text-center font-elegance fs-2">
                                    alle ore <strong className="font-serif">16:00</strong> nella <strong
                                    className="font-serif">Cattedrale di Lucera</strong>,<br/> in Piazza del Duomo
                                </p>
                            </div>
                        </div>
                        <div className="row justify-content-center align-items-center my-3">
                            <div className="col-10 col-md-5">
                                <img src="/img/chiesa_transparent_border.png" alt="Cattedrale di Lucera" className="img-fluid"/>
                            </div>
                        </div>
                        {/*<div className="row justify-content-center align-items-center my-3">*/}
                        {/*    <div className="col-10 col-md-5 mt-3 mt-md-0">*/}
                        {/*        <iframe title="Cattedrale" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2987.822084654486!2d15.332588876065813!3d41.50813017128482!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1339e1e875266983%3A0xd3e3aa9aee8acbaa!2sBasilica%20cattedrale%20di%20Santa%20Maria%20Assunta%2C%20Piazza%20del%20Duomo%2C%2071036%20Lucera%20FG!5e0!3m2!1sit!2sit!4v1702804012458!5m2!1sit!2sit" width="100%" height="300" style={{border: "0"}} allowFullScreen={true} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="row justify-content-center my-4 mt-5">
                            <div className="col-8 col-md-6 col-lg-4 text-center my-3">
                                <a className="btn btn-lg btn-EN" target={"_blank"} rel="noreferrer"
                                   href={"https://maps.google.com/maps/dir//Basilica+cattedrale+di+Santa+Maria+Assunta+Piazza+del+Duomo+71036+Lucera+FG/@41.5081302,15.3351638,16z/data=!4m5!4m4!1m0!1m2!1m1!1s0x1339e1e875266983:0xd3e3aa9aee8acbaa"}>
                                    Raggiungi la Cattedrale
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <Divider></Divider>
                <div className="row justify-content-center align-items-center min-vh-100 sfondo-center">
                    <div className="col-12">
                        <div className="row justify-content-center my-3">
                            <div className="col-12 col-md-10 col-lg-8 text-center my-3">
                                <p className="text-center font-elegance fs-2">
                                    Dopo la cerimonia Vi aspettiamo per festeggiare presso <br/> <strong
                                    className="font-serif">"I giardini di Flos"</strong>
                                </p>
                            </div>
                            <div className="col-12 col-md-10 col-lg-8 text-center my-3">
                                <p className="text-center font-elegance fs-2">
                                    a Lucera, in Via per Troia, km 0,5
                                </p>
                            </div>
                        </div>
                        <div className="row justify-content-center my-3">
                            <div className="col-10 col-md-5">
                                <img src="/img/flos_transparent_border.png" alt="Immagine dei giardini di Flos"
                                     className="img-fluid"/>
                            </div>
                        </div>
                        <div className="row justify-content-center my-4 mt-5">
                            <div className="col-8 col-md-6 col-lg-4 text-center my-3">
                                <a className="btn btn-lg btn-EN" target={"_blank"} rel="noreferrer"
                                   href={"https://www.google.com/maps/place/I+Giardini+di+Flos+Eventi,+SP109,+km+0,5+Via+per+Troia,+71036+Lucera+FG/@41.4985191,15.3336327,17z/data=!4m6!3m5!1s0x1339e17f313f213b:0x23a94e29a4fa60b9!8m2!3d41.4985191!4d15.3336327!16s%2Fg%2F11h0w9qxv0?gl=it"}>
                                    Raggiungi la sala
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <Divider></Divider>
                <div className="row justify-content-center align-items-center min-vh-100 sfondo-center">
                    <div className="col-12">
                        <div className="row justify-content-center my-3">
                            <div className="col-12 col-md-10 col-lg-8 text-center">
                                <h4 className="text-center font-elegance fs-1">
                                    Conferma la tua presenza
                                </h4>
                            </div>
                        </div>
                        <div className="row justify-content-center my-3 fs-4">
                            <form onSubmit={handleSubmit} className="col-12 col-md-10 col-lg-8">
                                <div className="row justify-content-between mb-3">
                                    <div className="col-12 col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="inputName" className="form-label">Nome</label>
                                            <input type="text" className="form-control" id="inputName"
                                                   aria-describedby="emailHelp" value={name}
                                                   onChange={(e) => setName(e.target.value)} required></input>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="inputSurname" className="form-label">Cognome</label>
                                            <input type="text" className="form-control" id="inputSurname"
                                                   aria-describedby="emailHelp" value={surname}
                                                   onChange={(e) => setSurname(e.target.value)} required></input>
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-between mb-3">
                                    <div className="col-12 col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="inputName" className="form-label">Numero ospiti</label>
                                            <input type="number" className="form-control" id="inputNumber" min={0}
                                                   max={10}
                                                   value={host} onChange={(e) => setHost(e.target.value)}
                                                   required></input>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="presenceConfirm" className="form-label">Confermi la tua
                                                presenza?</label>
                                            <select name="presenceConfirm" id="presenceConfirm" className="form-select"
                                                    required
                                                    onChange={handleOnChangePresence}>
                                                <option value="">Seleziona una risposta</option>
                                                <option value="yes">Ci sarò</option>
                                                <option value="no">Mi dispiace, non potrò esserci</option>
                                            </select>
                                        </div>
                                    </div>
                                    {/*{presenceSelection && (*/}
                                    {/*    <div className="col-12">*/}
                                    {/*        <div className="mb-3 form-check">*/}
                                    {/*            <label className="form-check-label fs-5" htmlFor="kidMenu">Per me vorrei un menù bambino</label>*/}
                                    {/*            <input type="checkbox" className="form-check-input" id="kidMenu" checked={kidMenu} onChange={(e) => setKidMenu(e.target.checked)}></input>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*)}*/}
                                </div>
                                {inputHosts.length > 0 && presenceSelection && (
                                    <div className="row justify-content-center mb-4">
                                        <div className="col-12">
                                            {inputHosts}
                                        </div>
                                    </div>
                                )}
                                <div className="row justify-content-between mb-4">
                                    <div className="col-12">
                                        <div className="mb-3">
                                            <label htmlFor="inputName" className="form-label">Intolleranze e/o
                                                allergie</label>
                                            <textarea name="intolerances" id="intolerancesInput" cols="100%" rows="10"
                                                      className="form-control" value={note}
                                                      onChange={(e) => setNote(e.target.value)}></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-center mb-3">
                                    <div className="col-8 col-md-6 col-lg-4 text-center">
                                        <button className="btn btn-lg btn-EN">Conferma</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <Divider></Divider>
                <div className="row justify-content-center align-items-center min-vh-100 sfondo-center">
                    <div className="col-12">
                        <div className="row justify-content-center my-3">
                            <div className="col-12 col-md-10 col-lg-8 text-center">
                                <h4 className="text-center font-elegance fs-1">
                                    Se ti va, contribuisci al nostro viaggio di nozze
                                </h4>
                            </div>
                        </div>
                        <div className="row justify-content-center mb-5 fs-4 mt-5">
                            <div className="col-12 col-md-10 col-lg-8 text-center">
                                <img src="/img/trip.png" alt="Viaggio di nozze" className="img-fluid" style={{height: '200px'}}/>
                            </div>
                        </div>
                        <div className="row justify-content-center mb-5 mt-5">
                            <div className="col-12 col-md-10 col-lg-8 text-center fs-4">
                                <strong>IBAN</strong> <br/><strong className="ms-2">IT25J0306234210000002084130</strong>
                            </div>
                            <div className="col-12 col-md-10 col-lg-8 text-center my-4">
                                <strong>Intestatario</strong> <br/><span className="ms-2">Enrica Fantini</span>
                            </div>
                        </div>
                        <div className="row justify-content-center mb-5 fs-4">
                            <div className="col-12 col-md-10 col-lg-8 text-center">
                                <button onClick={() => handleOnClickToCopyIban("IT25J0306234210000002084130")} className="btn btn-EN">{copied ? "Copiato!" : "Copia IBAN"}</button>
                            </div>
                        </div>
                        <div className="row justify-content-center align-items-center">
                            <div className="col-12">
                                <div className="row justify-content-center align-items-center mb-5 fs-4">
                                    <div className="col-7 col-md-6 text-start order-1 px-0">
                                        <a href="/admin" className="text-decoration-none">
                                            <img src="/img/vega-transparent.PNG" alt="Pastore tedesco"
                                                 className="img-fluid"/>
                                        </a>
                                    </div>
                                    <div className="col-5 col-md-6 order-2 px-0">
                                        <div className="mb-5">
                                            <span className="display-1 font-elegance">
                                                Grazie
                                            </span>
                                        </div>
                                        <div>
                                            <a href="tel:003924163838" className="text-decoration-none my-auto">
                                                <img src="/img/phone-icon.png" alt="Icona del telefono" className="img-fluid" style={{height: "30px"}}/>
                                                <span className="ms-2">Telefono</span>
                                            </a>
                                        </div>
                                        <div className="mt-2">
                                            <a href="mailto:nicolavitrani93@gmail.com"
                                               className="text-decoration-none font-serif mt-4">
                                                <img src="/img/mail-icon.png" alt="Icona del telefono"
                                                     className="img-fluid" style={{height: "30px"}}/>
                                                <span className="ms-2">Email</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default App;
